import i18next, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";

import { LoggerHelper } from "core-framework";
import translationTurkce from "./resources/tr.json";
import translationEnglish from "./resources/en.json";
import translationDeutsch from "./resources/de.json";
// import translationEnglish from "./translations/english.json";
//import * as resources from "./resources";
/*
Kullanım1:
import { useTranslation } from "react-i18next";
const {t, i18n} = useTranslation();  

i18n.changeLanguage("en"); globolde değiştirir

Kullanım2:

import { t } from "i18next";

Okumak güzel olur:https://dogan-ay.medium.com/multilanguage-%C3%A7oklu-dil-yap%C4%B1s%C4%B1-react-js-i18next-2a9b018f1c74
*/

const initializeI18n = (props) => {
  // const dispatch = useDispatch();

  // const auth = useSelector((state: IStoreState) => state.auth);
  LoggerHelper.LogInfo("Initialized I18n");
  //const ns = Object.keys(Object.values(resources)[0]); 

  const opt: InitOptions = { 
    //defaultNS: ns,//modüller
    interpolation: {
      escapeValue: false,
    },
    resources: {
      tr: {
        translation: translationTurkce,
      },
      en: {
        translation: translationEnglish,
      },
      de: {
        translation: translationDeutsch,
      },
    },
    // resources: {
    //   ...Object.entries(resources).reduce(
    //     (acc, [key, value]) => ({
    //       ...acc,
    //       [key]: value,
    //     }),
    //     {}
    //   ),
    // },
    lng: props?.template?.language ?? "en",
    fallbackLng: "en",// Dil bulunamazsa kullanılacak dil
    compatibilityJSON: "v3",
  };

  return i18next.use(initReactI18next).init(opt);
};

export default initializeI18n;
