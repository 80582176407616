//Değer atamasına başına reducer ismini yaz
//yoksa tüm reducerlara gidiyor 

//Action type for Action interface
export enum templateActionTypeEnum {
  Init = "@@INIT",
  SideBarShow = "templateSideBarShow",
  ChangeNotificationCount = "changeNotificationCount",
  Language= "language",
  UTCTime= "utcTime"
}

//Payload interface
export interface ITemplateState {
  sidebarShow: true | false | "responsive";
  notificationCount: number;
  language: string,
  utcTime: string
}

//Action interface
export interface ITemplateAction {
  type: templateActionTypeEnum;
  payload: any;
}

//Payload inital value
export const templateInitialState: ITemplateState = {
  sidebarShow: "responsive", //true: sidebar gözükür, false:sidebar gözükmez, responsive:mobilde gizli olarak
  notificationCount: 0,
  language : "en-US",
  utcTime : "Europe/Istanbul"
};
