import moment from "moment"; 

export class FormatHelper { 

  /**Datetime formatında ki DD.MM.YYYY HH:mm:ss Olarak döner */
  static toDateTimeFormat(value: any, utcTime: number = 3): string {
    if (value)
      return moment(value).add("hours", utcTime).format("DD.MM.YYYY HH:mm:ss");

    return "";
  }
  /**Datetime formatında ki DD.MM.YYYY Olarak döner */
  static toDateFormat(value: any, utcTime: number = 3): string {
    if (value) return moment(value).add("hours", utcTime).format("DD.MM.YYYY");

    return "";
  }
  /**Metini local halini döner */
  static getLocalText(value: any, t: any, prop: string): string {
    if (value) return t(`${prop}.${value}`);

    return "";
  }
  /**Datetime formatında ki YYYY-MM-ddTHH:mm:ss Olarak döner
   * Api get isteklerinde datetime gönderilmek istendiğinde proxy sınıfını burayı kullanır
   */
  static toDateTimeApiRequestFormat(value: Date, utcTime: number = 3): string {
    if (value)
      return moment(value).add("hours", utcTime).format("YYYY.MM.DDTHH:mm:ss");

    return "";
  }
  /**
   * 2 tarih arasında farkı HH:SS:ss olarak döner
   * @param startDate
   * @param endDate
   * @returns
   */
  static getTimeDifference(
    startDate: string | Date,
    endDate: string | Date
  ): string {
    const start = moment(startDate);
    const end = moment(endDate);

    const duration = moment.duration(end.diff(start));
    const hours = Math.floor(duration.asHours()); // Toplam saat
    const minutes = duration.minutes(); // Kalan dakika
    const seconds = duration.seconds(); // Kalan saniye

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  static toBoolFormatYesNo(value: any): string {
    if (value) return "Evet";
    else return "Hayır";
  }

  static toEnumText(enm: any, value: number): any {
    return Object.values(enm)[value];
  }

  static byteToMB(value: number): any {
    return (value / 1048576).round(2);
  }

  static toMoneyAmountFormat(value: any): string {
    return value.toLocaleString("tr-TR", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
}
